import Button from 'components/common/Button'
import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import SmallButton from 'components/common/SmallButton'
import useStoreServiceQuery from 'hooks/query/service/useStoreServiceQuery'
import useStoreQuery from 'hooks/query/store/useStoreQuery'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import storeQuestionData from 'data/storeQuestionData.json'
import useDeleteStore from 'hooks/query/store/useDeleteStore'
import { useRef, useState } from 'react'
import ConfirmModal from 'components/common/ConfirmModal'
import FooterButtonBox from 'components/common/FooterButtonBox'
import { useScrollGet } from 'hooks/useScrollGet'
import { isMobileSafari } from 'react-device-detect'
import availableSeoulGu from 'data/availableSeoulGu.json'
import availableOtherGu from 'data/availableOtherGu.json'
import { ROUTES } from 'utils/common/routes'

import useCheckAvailableServiceAreaQuery from 'hooks/query/service/useCheckAvailableServiceAreaQuery'

export default function StoreDetailPage() {
  const history = useHistory()
  const mainRef = useRef<HTMLDivElement>(null)
  const { storeId } = useParams<{ storeId: string }>()
  const { totalOffsetY } = useScrollGet({})
  const { data: store } = useStoreQuery({ id: storeId }, { refetchOnWindowFocus: true })
  const { data: services, isError: isServiceError } = useStoreServiceQuery(
    { storeId },
    { enabled: !!store, retry: 0, refetchOnWindowFocus: true }
  )
  const [outOfServiceModal, setOutOfServiceModal] = useState<boolean>(false)

  const { mutateAsync: mutateDeleteStore } = useDeleteStore()
  const [isDeleteWarning, setIsDeleteWarning] = useState(false)

  // const isNotPaid = !service?.payment_id || (service.payment_id && !service.paid_at)
  const isDisablePostService =
    services &&
    (services.length >= 2 ||
      services.find(({ payment_id, paid_at, is_refund }) => !payment_id || (payment_id && !paid_at) || is_refund))

  const { mutateAsync: mutateCheckAvailableServiceArea } = useCheckAvailableServiceAreaQuery()

  const fetchCheckServiceArea = async (sido: string, sigungu: string) => {
    const checkResult = await mutateCheckAvailableServiceArea({sido: sido, sigungu: sigungu})
    setOutOfServiceModal(!checkResult)

    return !checkResult
  }

  const handleServiceApplyClick = async () => {
    // (~ 2024.12.13) 이전 로직 (비교를 위해 삭제하지 않음)
    //    - "서울 중구, 울산 중구" 모두 서비스 신청이 가능한 오류 있음
    // if (
    //   (store?.sido === '인천' && store?.sigungu === '중구') ||
    //   (availableSeoulGu.filter(({ name }) => name === store?.sigungu).length === 0 &&
    //     availableOtherGu.filter(({ name }) => store?.sigungu.includes(name)).length === 0)
    // ) {
    //   console.log("sdfadf")
    //   if (store?.sido !== '부산')
    //     return setOutOfServiceModal(true)
    // }

    // (2024.12.13 ~) DB 에서 서비스 신청 가능 지역 체크
    //    - 위의 오류가 없게 "DB 데이터를 구성함"
    //    - "서울 중구" 만 허용하게 데이터 저장해 놓음 (DB (service_available_area)의 "sido=서울", "sigungu=중구" 로 저장)
    if (store?.sido === undefined || store?.sigungu === undefined)
      return

    const checkResult = await fetchCheckServiceArea(store?.sido, store?.sigungu)

    if (checkResult)
      return

    history.push(`/service-post/${store?.id}/basic`)
  }

  const isPaid = !!(
    services &&
    (services.length === 2 || (services.length === 1 && services[0].payment_id && services[0].paid_at))
  )

  const handleDeleteStore = async () => {
    await mutateDeleteStore({ id: storeId })
    history.push(ROUTES.STORE)
  }

  return (
    <>
      <Header title="매장 상세 정보" offsetY={totalOffsetY} />
      <StyledMain ref={mainRef}>
        <Container>
          <header>
            <StoreName>{store?.store_name}</StoreName>
            <SmallButton
              buttonTheme={isDisablePostService ? 'disabled' : 'primary'}
              onClick={isDisablePostService ? undefined : () => handleServiceApplyClick()}
            >
              서비스 신청
            </SmallButton>
          </header>

          <QuestionList isMobileSafari={isMobileSafari}>
            {storeQuestionData.map(({ subject, question, type }, i) => {
              if (!store) return null
              let answer = ''

              type ObjType = {
                [index: string]: any
              }
              const storeObj: ObjType = store

              if (subject === 'address') {
                answer = `${store.address_name} ${store.detail_address}`
              } else if (type === 'check') {
                answer = storeObj[subject].map((item: string, i: number) =>
                  i + 1 !== storeObj[subject].length ? item + ', ' : item
                )
              } else {
                answer = storeObj[subject]
              }

              return (
                <li key={subject}>
                  <p>
                    {i + 1}. {question}
                  </p>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• {answer}</p>
                </li>
              )
            })}
          </QuestionList>
        </Container>

        <ButtonContainer>
          {(isServiceError || !services || !isPaid) && (
            <Button width={160} buttonTheme="red" onClick={() => setIsDeleteWarning(true)}>
              삭제
            </Button>
          )}
          <Button
            width={160}
            buttonTheme={isServiceError || !services || !isPaid ? 'primary' : 'disabled'}
            onClick={() => history.push(`/store-modify/${store?.id}`)}
          >
            정보 변경
          </Button>
        </ButtonContainer>
        <FooterButtonBox isWarning={isPaid} warningText="서비스 이용중에는 변경할 수 없습니다.">
          {(isServiceError || !services || !isPaid) && (
            <Button width={120} buttonTheme="red" onClick={() => setIsDeleteWarning(true)}>
              삭제
            </Button>
          )}
          <Button
            width={120}
            buttonTheme={isServiceError || !services || !isPaid ? 'primary' : 'disabled'}
            onClick={() => history.push(`/store-modify/${store?.id}`)}
          >
            정보 변경
          </Button>
        </FooterButtonBox>
        {isPaid && <Warning>서비스 이용중에는 변경할 수 없습니다.</Warning>}
      </StyledMain>
      <Footer />

      {/* MODAL */}
      <ConfirmModal
        title="매장 삭제 안내"
        headerColor="warning"
        headline="삭제된 매장은 복구 되지 않습니다."
        subline={`• ${store?.store_name}`}
        open={isDeleteWarning}
        onConfirm={handleDeleteStore}
        confirmText="삭제"
        onClose={() => setIsDeleteWarning(false)}
        closeText="취소"
      />

      <ConfirmModal
        open={outOfServiceModal}
        title="서비스 지역 안내"
        headerColor="warning"
        headline="서울, 인천(강화 및 중구 일부 제외), 성남, 수원 외 다른 지역의 서비스 신청은 문의 주세요."
        confirmText="1:1 문의하기"
        closeText="취소"
        onClose={() => setOutOfServiceModal(false)}
        onConfirm={() => {
          window.open('https://brwnie.channel.io/')
        }}
      />
    </>
  )
}

const StyledMain = styled.main`
  margin: 0 auto;
  padding: 80px 0 104px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  // mobile
  ${({ theme }) => theme.media.mobile`
    padding-top: 88px;
    
  `}
`

const Container = styled.section`
  width: 464px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.lightGrayishBlue};

  header {
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 32px;
    background-color: ${({ theme }) => theme.colors.lightGray};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .text_container {
      display: flex;
      flex-direction: column;

      a {
        font-size: 20px;
        line-height: 28px;
        color: ${({ theme }) => theme.colors.black};
        font-weight: 700;
        margin-top: 8px;
        text-decoration: none;
      }
    }

    .button_container {
      display: flex;
      align-items: center;

      button ~ button {
        margin-left: 8px;
      }
    }
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    header {
      padding: 16px 12px;
      position: relative;
      height: 88px;

      .text_container {
        position: absolute;

        a {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .button_container {
        position: absolute;
        right: 12px;
      }
    }

    width: ${theme.width.mobile}
  `}
`

const StoreName = styled.h4`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  // mobile
  ${({ theme }) => theme.media.mobile`
    font-size: 14px;
    line-height: 20px;
    width: 110px;
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 32px;

  button ~ button {
    margin-left: 8px;
  }

  // mobile
  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`

const QuestionList = styled.ul<{ isMobileSafari: boolean }>`
  padding: 32px;
  height: 644px;
  overflow: overlay;

  li {
    p {
      color: ${({ theme }) => theme.colors.black};
      font-size: 12px;
      line-height: 20px;
      word-break: keep-all;
    }

    p:first-child {
      font-weight: 700;
    }

    p:last-child {
      font-weight: 400;
    }
  }

  li ~ li {
    margin-top: 24px;
  }
  /* pc에서 */
  @media only screen and (min-width: 768px) {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.gray};
      border-radius: 2px;
    }
  }

  // mobile
  ${({ theme, isMobileSafari }) =>
    isMobileSafari &&
    theme.media.mobile`
    padding: 32px 16px;
    height: 440px;

    li ~ li {
      margin-top: 16px;
    }

  `}

  ${({ theme, isMobileSafari }) =>
    !isMobileSafari &&
    theme.media.mobile`
    padding: 32px 16px;
    height: 440px;

    li ~ li {
      margin-top: 16px;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
      }

  `}
`

const Warning = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.warning};
  margin-top: 16px;

  // mobile
  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`

// const CancelContent = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;

//   p {
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 20px;
//     color: ${({ theme }) => theme.colors.black};
//   }

//   a {
//     display: inline-block;
//     align-self: center;
//     margin-top: 24px;
//     height: 24px;
//     line-height: 24px;
//     font-size: 16px;
//     font-weight: 400;
//     color: ${({ theme }) => theme.colors.black};
//     text-decoration: none;
//     border-bottom: 1px solid ${({ theme }) => theme.colors.black};
//   }
// `
