import client from '../client'

export async function getCheckAvailabeServiceArea({
    sido,
    sigungu
  }: GetAddressParam) {
  const response = await client.get<boolean>(`/service-api/v1/service/available`, {
    params: {
      sido,
      sigungu
    },
  })

  console.log("response.data : ", response.data)
  return response.data
}

export type GetAddressParam = {
  sido: string
  sigungu: string
}
