import { useState } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import useVisitHourBottomSheet from 'hooks/service/useVisitHourBottomSheet'

import {
  selectServiceWeeklyCount,
  setSchedules,
  SchedulesType,
  selectCloseTime,
  ScheduleType,
  ServiceDayValueType,
} from 'modules/features/service/serviceSlice'

import CalendarForm from 'components/ServicePostPage/CalendarForm'

import { getNow, checkIsHoliday} from 'utils/common/calendar'
import { ListRow, TypoP1 } from 'ohds-web'

const { presentYear, presentMonth } = getNow()

export type DatesType = {
  date: number
}[]

export default function FirstVisitCalendar() {
  const dispatch = useAppDispatch()
  const { serviceWeek, serviceDayValues, serviceHour } = useAppSelector((state) => state.service)

  const serviceWeeklyCount = useAppSelector(selectServiceWeeklyCount)
  const closeTimeSchedules = useAppSelector(selectCloseTime)

  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleType>()

  const [openVisitHourOptions] = useVisitHourBottomSheet()

  const createFirstWeekDates = (selectedDate: number, selectedDay: number) => {
    const firstWeekDates: DatesType = []

    for (let i = 0; i < serviceWeeklyCount; i++) {
      const day = serviceDayValues[i]
      const isBeforeFirstDay = selectedDay > day

      if (!isBeforeFirstDay) {
        firstWeekDates.push({
          date: selectedDate + (day - selectedDay),
        })
      }
    }

    return firstWeekDates
  }

  const createMiddleWeekDates = (selectedDate: number, selectedDay: number, weekIndex: number) => {
    const middleWeekDates: DatesType = []

    for (let i = 0; i < serviceWeeklyCount; i++) {
      const day = serviceDayValues[i]

      middleWeekDates.push({
        date: selectedDate + 7 * weekIndex + day - selectedDay,
      })
    }

    return middleWeekDates
  }

  const createLastWeekDates = (selectedDate: number, selectedDay: number, serviceWeekDisplayOnCalendar: number) => {
    const lastWeekDates: DatesType = []

    for (let i = 0; i < serviceWeeklyCount; i++) {
      const day = serviceDayValues[i]
      const isBeforeFirstDay = selectedDay > day

      if (isBeforeFirstDay) {
        lastWeekDates.push({
          date: selectedDate + 7 * (serviceWeekDisplayOnCalendar - 1) + day - selectedDay,
        })
      }
    }

    return lastWeekDates
  }

  const createAllWeekDates = (selectedDate: number, selectedDay: number) => {
    const dates: DatesType = []
    if (serviceWeek === undefined) return []

    dates.push(...createFirstWeekDates(selectedDate, selectedDay))

    for (let weekIndex = 1; weekIndex < serviceWeek; weekIndex++) {
      dates.push(...createMiddleWeekDates(selectedDate, selectedDay, weekIndex))
    }

    const isSelectFirstServiceDay = serviceDayValues[0] === selectedDay

    if (!isSelectFirstServiceDay) {
      const serviceWeekDisplayOnCalendar = isSelectFirstServiceDay ? serviceWeek : serviceWeek + 1

      dates.push(...createLastWeekDates(selectedDate, selectedDay, serviceWeekDisplayOnCalendar))
    }

    return dates
  }

  // 공휴일 개수만큼 전달받은 날짜 이후로 날짜 return (스케줄 요일에 맞게)
  const createAdditionalDatesInsteadOfHoliday = (year: number, month: number, date: number, holidayCount: number) => {
    const additionalDates = []
    const newStartDate = new Date(year, month - 1, date)

    let count = 0;
    let currentDate = new Date(newStartDate);

    // holidayCount 만큼 반복
    while (count < holidayCount) {
      currentDate.setDate(currentDate.getDate() + 1)

      // 0 ~ 6 = 월요일 ~ 일요일
      const dayOfWeek  = currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1

      if (serviceDayValues.includes(dayOfWeek as ServiceDayValueType)) {
        if (!checkIsHoliday(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate())) {
          additionalDates.push({
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1, // 월은 0부터 시작하므로 1을 더함
            date: currentDate.getDate(),
            hour: serviceHour,
          })
          count++
        }
      }
    }

    return additionalDates
  }

  const createSchedules = (year: number, month: number, date: number, day: number) => {
    const dates = createAllWeekDates(date, day)

    const mySchedules = dates.map(({ date }) => {
      const degree = date - dates[0].date
      const firstDate = new Date(year, month - 1, dates[0].date)
      const currentDate = new Date(firstDate.setDate(firstDate.getDate() + degree))
      return {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
        date: currentDate.getDate(),
        hour: serviceHour,
      }
    })

    // 2025.01.08. 부터 변경되는 사항
    //  1) 공휴일로 체크되는 날짜는 삭제
    let holidayCount = 0
    const mySchedulesExcludeHoliday = mySchedules.filter(({year, month, date, hour}) => {
      if (checkIsHoliday(year, month, date)) {
        holidayCount++
        return false
      }
      return true
    })
    // console.log(`mySchedulesExcludeHoliday : `, mySchedulesExcludeHoliday)

    //  2) 제거된 공휴일 개수만큼 스케줄의 요일에 맞게 '마지막 날짜' 이후에 추가
    let newMySchedules = [...mySchedulesExcludeHoliday]
    if (holidayCount > 0) {
      const lastSchedule = mySchedulesExcludeHoliday[mySchedulesExcludeHoliday.length - 1];
      const { year: lastYear, month: lastMonth, date: lastDate } = lastSchedule;

      const additionalDates = createAdditionalDatesInsteadOfHoliday(lastYear, lastMonth, lastDate, holidayCount)

      newMySchedules = newMySchedules.concat(additionalDates)
    }
    // console.log(`newMySchedules : `, newMySchedules)

    return newMySchedules as SchedulesType
  }

  const handleDateButtonClick = async (year: number, month: number, date: number, day: number) => {
    const schedule = createSchedules(year, month, date, day)

    const isSelectCloseTime = !!closeTimeSchedules.find(
      (closeTime) =>
        closeTime.year === year &&
        closeTime.month === month &&
        closeTime.date === date &&
        closeTime.hour === serviceHour
    )

    if (isSelectCloseTime) {
      const selectedVisitHour = await openVisitHourOptions({
        title: `${month}월 ${date}일 ${serviceHour}시는 마감되었어요 시작일의 시간만 바꿔드릴게요`,
        year,
        month,
        date,
        OtherOption: (
          <ListRow UpTypo={<TypoP1 text="다른 날로 변경할래요" />} rightIconName="LineArrowRight" onClick={() => {}} />
        ),
      })

      if (selectedVisitHour !== undefined) {
        schedule[0].hour = selectedVisitHour
      }
    }

    setSelectedSchedule(schedule[0])
    dispatch(setSchedules(schedule))
  }

  return (
    <CalendarForm
      type="firstVisit"
      firstYear={presentYear}
      firstMonth={presentMonth}
      newSchedule={selectedSchedule}
      onDateClick={handleDateButtonClick}
    />
  )
}
