import { BottomCTA, ListRow, TypoP1 } from 'ohds-web'

import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook'
import useVisitHourBottomSheet from 'hooks/service/useVisitHourBottomSheet'
import useServicePostPush from 'hooks/service/useServicePostPush'

import {
  selectInvalidDaySchedulesCount,
  selectInvalidTimeSchedulesCount,
  selectInvalidTimeSchedules,
  setInvalidTimeFixSchedules,
  selectHolidaySchedules,
  ScheduleType,
  setSchedules,
} from 'modules/features/service/serviceSlice'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import styled from 'styled-components'
import { addAlternateDay } from 'utils/common/service'

export type FirstVisitBottomCTAProps = {
  linear: boolean
  label: string
}

export default function FirstVisitBottomCTA({ linear, label }: FirstVisitBottomCTAProps) {
  const { pushToInvalidDay, pushToConfirm, pushToInvalidTime } = useServicePostPush()

  const dispatch = useAppDispatch()

  const invalidDaySchedulesCount = useAppSelector(selectInvalidDaySchedulesCount)
  const invalidTimeSchedulesCount = useAppSelector(selectInvalidTimeSchedulesCount)
  const invalidTimeSchedules = useAppSelector(selectInvalidTimeSchedules)
  const holidaySchedule = useAppSelector(selectHolidaySchedules)

  const { serviceWeek, schedules, serviceDayValues } = useAppSelector((state) => state.service)

  const hasInvalidDay = invalidDaySchedulesCount !== 0
  const hasInvalidTime = invalidTimeSchedulesCount !== 0

  const [openVisitHourOptions] = useVisitHourBottomSheet()

  const resolveInvalidTimeSchedules = async () => {
    const timeDataArr = []
    for (let i = 0; i < invalidTimeSchedulesCount; i++) {
      const { year, month, date, hour } = invalidTimeSchedules[i]

      const selectedVisitHour = await openVisitHourOptions({
        title: `${month}월 ${date}일 ${hour}시는 마감되었어요 다른 시간으로 바꿔드릴게요`,
        year,
        month,
        date,
        OtherOption: (
          <ListRow
            UpTypo={<TypoP1 text="다른 날로 변경할래요" />}
            rightIconName="LineArrowRight"
            onClick={() => pushToInvalidTime(i)}
          />
        ),
      })

      if (selectedVisitHour !== undefined) {
        timeDataArr.push({
          year,
          month,
          date,
          hour: selectedVisitHour,
        })
      }
    }
    dispatch(setInvalidTimeFixSchedules([...timeDataArr]))
  }

  const handleNextButtonClick = async () => {
    if (hasInvalidDay) return pushToInvalidDay(0)
    if (hasInvalidTime) await resolveInvalidTimeSchedules()

    const { length: holydayLength } = holidaySchedule
    let lastSchedule: ScheduleType = schedules[schedules.length - 1]

    if (holydayLength > 0) {
      const arr = addAlternateDay(lastSchedule, serviceDayValues, holydayLength)
      const mySchedule: ScheduleType[] = [...schedules, ...arr]
      dispatch(setSchedules(mySchedule))
    }

    pushToConfirm()
    LogManager.Instance.sendLog(
      'service_post_grade',
      {
        service_post_grade: '2_서비스시작일',
      },
      LOGGER.GTM
    )
  }

  return (
    <StyledBottomCTA
      linear={linear}
      primaryProps={{
        label,
        onClick: handleNextButtonClick,
      }}
    />
  )
}

const StyledBottomCTA = styled(BottomCTA)`
  @media only screen and (min-width: 768px) {
    width: 50vw;
    left: 50%;
  }
`
