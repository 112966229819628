import { useAppSelector } from 'hooks/useReduxHook'
import { selectCloseDay } from 'modules/features/service/serviceSlice'
import { TypoC2 } from 'ohds-web'
import { useEffect, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { checkIsHoliday, getDayOfTheWeek, getNow } from 'utils/common/calendar'
import serviceData from 'data/serviceData.json'
import useStoreServiceQuery from 'hooks/query/service/useStoreServiceQuery'
import { useParams } from 'react-router-dom'
import useBvsQuery from 'hooks/query/bvs/useBvsQuery'

const { presentYear, presentMonth, presentDate } = getNow()

export type CalendarDateProps = {
  year: number
  month: number
  date: number
  day: number
  label?: string
  selected?: boolean
  inactive?: boolean
  onDateClick: () => void
}

export default function CalendarDate({
  year,
  month,
  date,
  day,
  label,
  selected,
  inactive,
  onDateClick,
}: CalendarDateProps) {
  const ref = useRef<HTMLDivElement>(null)

  const { schedules, serviceWeek } = useAppSelector((state) => state.service)
  const closeDay = useAppSelector(selectCloseDay)
  const { storeId } = useParams<{ storeId: string }>()

  const isIncludeCloseDay = useMemo(() => {
    if (serviceWeek === 12 || serviceWeek === 24) {
      return false
    }
    return !!closeDay.find((day) => day.year === year && day.month === month && day.date === date)
  }, [closeDay, date, month, serviceWeek, year])

  const isClose = isIncludeCloseDay || (year === 2023 && month === 6 && date === 21)

  const isHoliday = checkIsHoliday(year, month, date)

  const isToday = year === presentYear && month === presentMonth && date === presentDate

  // 임시
  // const isWeekendInactive = storeAddressType === 'other' && (day === 5 || day === 6)
  const isWeekendInactive = false

  const { data: services } = useStoreServiceQuery({ storeId })
  const service = services && services[0]
  const { data: bvs } = useBvsQuery({ id: service ? service.bvs_id : 0 }, { retry: 0, enabled: !!service })

  const { minYear, minMonth, minDate } = useMemo(() => {
    const { minTerm } = serviceData
    let minDate = presentDate

    for (let i = 0; i < minTerm; i++) {
      minDate += 1

      while (true) {
        // 임시
        const dayOfTheWeek = getDayOfTheWeek(presentYear, presentMonth, minDate)
        if (dayOfTheWeek === '토' || dayOfTheWeek === '일' || checkIsHoliday(presentYear, presentMonth, minDate))
          minDate++
        else break
      }
    }

    if (bvs) {
      const reservationDates = bvs.reservation_dates.sort()
      const lastReservationDate = new Date(reservationDates[reservationDates.length - 1])

      if (new Date(presentYear, presentMonth - 1, minDate).getTime() < lastReservationDate.getTime()) {
        return {
          minYear: lastReservationDate.getFullYear(),
          minMonth: lastReservationDate.getMonth() + 1,
          minDate: lastReservationDate.getDate() + 1,
        }
      }
    }

    const DateClass = new Date(presentYear, presentMonth - 1, minDate)

    return {
      minYear: DateClass.getFullYear(),
      minMonth: DateClass.getMonth() + 1,
      minDate: DateClass.getDate(),
    }
  }, [bvs])

  const isBeforeMinDate =
    year < minYear ||
    (year === minYear && month < minMonth) ||
    (year === minYear && month === minMonth && date < minDate)

  useEffect(() => {
    if (isToday) {
      ref.current?.scrollIntoView()
    }
  }, [isToday])

  useEffect(() => {
    if (schedules.length === 0) return

    const { year: firstYear, month: firstMonth, date: firstDate } = schedules[0]

    if (firstYear === year && firstMonth === month && firstDate === date) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [year, month, date, schedules])

  return (
    <DateContainer empty={date === 0} ref={ref}>
      <DateButton
        selected={!isHoliday && !isClose && selected}
        inactive={isBeforeMinDate || inactive || isClose || isWeekendInactive}
        holiday={isHoliday}
        disabled={isBeforeMinDate || isHoliday || isClose || inactive || isWeekendInactive}
        onClick={onDateClick}
      >
        {date}
      </DateButton>
      {!isHoliday && !isClose && label && (
        <TypoC2
          text={label}
          fontWeight={selected ? 'bold' : 'regular'}
          color={selected && inactive ? 'brand200' : selected ? 'brand300' : inactive ? 'gray500' : undefined}
        />
      )}
      {!isToday && isHoliday && <TypoC2 text="공휴일" color="red400" />}
      {!isToday && isClose && <TypoC2 text="마감" color="gray500" />}
      {isToday && (
        <TodayLabel>
          <TypoC2 text="오늘" color="gray0" />
        </TodayLabel>
      )}
    </DateContainer>
  )
}

const DateContainer = styled.div<{ empty?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 64px;
  padding-top: 4px;
  width: 40px;

  ${({ empty }) =>
    empty &&
    css`
      visibility: hidden;
    `}
`

const DateButton = styled.button<{ selected?: boolean; inactive?: boolean; holiday?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--p1);
  font-weight: 500;
  background-color: transparent;
  color: var(--gray800);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  margin-bottom: 4px;

  ${({ selected, inactive }) =>
    selected && !inactive
      ? css`
          background-color: var(--brand300);
          color: var(--onBrand300);
          font-weight: 700;
        `
      : !selected && inactive
      ? css`
          color: var(--gray400);
          font-weight: 400;
          cursor: not-allowed;
        `
      : selected && inactive
      ? css`
          color: var(--brand200);
          font-weight: 400;
          cursor: not-allowed;
        `
      : undefined}

  ${({ holiday }) =>
    holiday &&
    css`
      color: var(--red400);
      font-weight: 400;
    `}
`

const TodayLabel = styled.div`
  border-radius: 12px;
  background-color: var(--gray500);
  width: 31px;
  text-align: center;
`
