import { useMutation } from 'react-query'
import { getCheckAvailabeServiceArea, GetAddressParam } from 'lib/api/service/getCheckAvailableServiceArea'
import { QueryOptionsOf } from 'lib/utils/types'
import { useQuery } from 'react-query'

// export default function useCheckAvailableServiceAreaQuery(value: GetAddressParam, options: QueryOptionsOf<typeof getCheckAvailabeServiceArea> = {}) {
//   console.log("sido : ", value.sido)
//   console.log("sigungu : ", value.sigungu)
//   return useQuery(createKey(value), () => getCheckAvailabeServiceArea(value), options)
// }

export default function useCheckAvailableServiceAreaQuery() {
  return useMutation((value: GetAddressParam) => getCheckAvailabeServiceArea(value))
}

const createKey = (value: GetAddressParam) => ['checkAvailableServiceArea',
  value.sido,
  value.sigungu
]
useCheckAvailableServiceAreaQuery.createKey = createKey
