import Icon from 'components/Icon/Icon'
import useAuth from 'hooks/query/auth/useAuth'
import useServicesQuery from 'hooks/query/service/useServicesQuery'
import { StoreType } from 'lib/api/store/types'
import styled, { css } from 'styled-components'
import EmptyService from './EmptyService'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useState } from 'react'

import availableSeoulGu from 'data/availableSeoulGu.json'
import availableOtherGu from 'data/availableOtherGu.json'
import { useDispatch } from 'react-redux'
import { initServiceState } from 'modules/features/service/serviceSlice'
import LogManager from 'lib/utils/logger'
import { LOGGER } from 'lib/utils/logger/types'
import ConfirmModal from 'components/common/ConfirmModal'
import ServiceItem from './ServiceItem/ServiceItem'
import ServiceItemLoading from './ServiceItemLoading'

import useCheckAvailableServiceAreaQuery from 'hooks/query/service/useCheckAvailableServiceAreaQuery'

interface StoreItemProp {
  store: StoreType
}

export default function StoreItem({ store }: StoreItemProp) {
  const [outOfServiceModal, setOutOfServiceModal] = useState<boolean>(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const { auth } = useAuth()
  const location = useLocation<{ isFirst?: boolean }>()

  const { data: services, isLoading } = useServicesQuery({ userId: auth?.id as number })

  const serviceList = services?.filter((service) => service.store_id === store.id)
  const isSubscribePaymentStore = (serviceList ?? []).some((service: any) => service && !!service.is_regular_payment)

  // 서비스 신청 불가능한 조건
  const isServiceApplyDisabled =
    isLoading ||
    // 서비스가 2개 이상이거나
    (serviceList && serviceList.length > 1) ||
    // 서비스가 자동 결제 상품이거나
    (serviceList && serviceList.length === 1 && serviceList[0].is_regular_payment) ||
    // 서비스 중 하나가 결제 전이거나 입금 대기 중이거나 환불된 경우
    (serviceList &&
      serviceList.find(
        ({ payment_id, paid_at, is_refund, payment_state }) =>
          !payment_id || (payment_id && !paid_at && payment_state === 'PENDING') || is_refund
      ))

  const { mutateAsync: mutateCheckAvailableServiceArea } = useCheckAvailableServiceAreaQuery()

  const fetchCheckServiceArea = async (sido: string, sigungu: string) => {
    const checkResult = await mutateCheckAvailableServiceArea({sido: sido, sigungu: sigungu})
    setOutOfServiceModal(!checkResult)

    return !checkResult
  }

  const handleServiceApplyClick = async () => {
    const { sigungu, sido } = store

    if (sigungu === null) return setOutOfServiceModal(true)

    // (~ 2024.12.13) 이전 로직 (비교를 위해 삭제하지 않음)
    //    - "서울 중구, 울산 중구" 모두 서비스 신청이 가능한 오류 있음
    // if (
    //   (sido === '인천' && sigungu === '중구') ||
    //   (availableSeoulGu.filter(({ name }) => name === sigungu).length === 0 &&
    //     availableOtherGu.filter(({ name }) => sigungu.includes(name)).length === 0)
    // ) {
    //   if (sido !== '부산')
    //   return setOutOfServiceModal(true)
    // }

    // (2024.12.13 ~) DB 에서 서비스 신청 가능 지역 체크
    //    - 위의 오류가 없게 "DB 데이터를 구성함"
    //    - "서울 중구" 만 허용하게 데이터 저장해 놓음 (DB (service_available_area)의 "sido=서울", "sigungu=중구" 로 저장)
    const checkResult = await fetchCheckServiceArea(sido, sigungu)

    if (checkResult)
      return

    dispatch(initServiceState())
    goServiceApply()
  }

  const goServiceApply = () => {
    const { id } = store

    LogManager.Instance.sendLog(
      'store_service_post',
      {
        page_url: location.pathname,
        btn_name: '서비스 신청',
        btn_url: `/service-post/${id}/basic`,
      },
      LOGGER.GTM
    )

    history.push(`/service-post/${id}/basic`)
  }

  return (
    <Container isSubscribe={isSubscribePaymentStore}>
      <TitleWrap isSubscribe={isSubscribePaymentStore}>
        <TopWrap>
          <StoreName>{store.store_name}</StoreName>
          <Link to={`/store-detail/${store.id}`}>
            <Icon name="LineGeneralEdit" className="edit-icon" />
          </Link>
        </TopWrap>
        {isSubscribePaymentStore && <SubscribePaymentInfo>📣 자동 결제 사용 매장</SubscribePaymentInfo>}
      </TitleWrap>

      {isLoading && <ServiceItemLoading />}
      <ServiceListContainer>
        {!isServiceApplyDisabled && <EmptyService handleApplyService={handleServiceApplyClick} />}
        {!isLoading && serviceList && serviceList.map((service) => <ServiceItem key={service.id} service={service} />)}
      </ServiceListContainer>

      <ConfirmModal
        open={outOfServiceModal}
        title="서비스 지역 안내"
        headerColor="warning"
        headline="서울, 인천(강화 및 중구 일부 제외), 성남, 수원 외 다른 지역의 서비스 신청은 문의 주세요."
        confirmText="1:1 문의하기"
        closeText="취소"
        onClose={() => setOutOfServiceModal(false)}
        onConfirm={() => {
          window.open('https://brwnie.channel.io/')
        }}
      />
    </Container>
  )
}

const Container = styled.li<{ isSubscribe: boolean }>`
  width: 100%;
  border-radius: 20px;
  margin-bottom: 12px;
  border: 1px solid #e2e8f0;

  ${({ isSubscribe }) =>
    isSubscribe &&
    css`
      align-self: stretch;
      border: 1px solid #718096;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    `}

  ${({ theme }) => theme.media.mobile`
        margin-bottom: 8px;
    `}
`

const TitleWrap = styled.div<{ isSubscribe: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #f4f7fb;
  border-bottom: 1px solid #e2e8f0;
  border-radius: 20px 20px 0px 0px;
  padding: 18px;

  ${({ isSubscribe }) =>
    isSubscribe &&
    css`
      background: #e2e8f0;
    `}

  ${({ theme }) => theme.media.mobile`
        padding: 16px;
  `}

  & > .edit-icon {
    cursor: pointer;
  }
`

const TopWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const SubscribePaymentInfo = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #4a5568;
  margin-top: 8px;
`

const StoreName = styled.h2`
  font-weight: 700;
  font-size: 20px;

  ${({ theme }) => theme.media.mobile`
      font-size: 16px;
  `}
`

const ServiceListContainer = styled.div`
  display: flex;

  & > div:first-child {
    border-right: 1px solid #f5f5f5;
  }

  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
    & > div:first-child {
      border-right: none;
      border-bottom: 1px solid #F5F5F5;
    }
  `}
`
